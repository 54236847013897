import { ellipsisStyles } from '../../shared/styles/commonStyles';

const styles = theme => ({
  container: {
    // backgroundImage: 'url(/images/dashboard/membership-promo.png)',
    borderRadius: 12,
    marginBottom: 24,
    paddingLeft: 16,
    height: 96,
    boxShadow: '0 12px 24px 0 rgba(27, 28, 31, 0.1)',
    position: 'relative',
    '&:hover': {
      boxShadow: '0 14px 26px 0 rgba(27, 28, 31, 0.1)'
    }
  },
  birdImageWrapper: {
    height: 100,
    width: 134,
    overflow: 'visible',
    position: 'relative'
  },
  birdImg: {
    objectFit: 'cover',
    height: '150%',
    width: '150%',
    position: 'absolute',
    top: -24
  },
  membershipTextOrange: {
    color: '#2663FF',
    fontFamily: 'Inter',
    fontSize: 64,
    fontWeight: 900,
    lineHeight: '72px',
    letterSpacing: '-0.72px',
    textTransform: 'uppercase',
    textShadow: '2px 2px 0 #bababa',
    '& span': {
      color: '#ffffff',
      fontStyle: 'italic',
      fontWeight: 600,
      textShadow: '2px 2px 0 #2663FF, -2px -2px 0 #2663FF, 2px -2px 0 #2663FF, -2px 2px 0 #2663FF'
    },
    [theme.breakpoints.down(1685)]: {
      fontSize: ({ catalog }) => (catalog ? 58 : 64),
      lineHeight: '60px'
    },
    [theme.breakpoints.down(1585)]: {
      fontSize: ({ catalog }) => (catalog ? 42 : 48),
      lineHeight: '60px'
    },
    [theme.breakpoints.down(1350)]: {
      fontSize: ({ catalog }) => (catalog ? 36 : 40),
      lineHeight: '52px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: ({ catalog }) => (catalog ? 28 : 32),
      lineHeight: '46px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 36,
      lineHeight: '38px'
    }
  },
  membershipTextWhiteUnderlineJoin: {
    color: '#1300cf',
    fontFamily: 'Inter',
    fontSize: 36,
    fontStyle: 'italic',
    fontWeight: 900,
    lineHeight: '40px',
    letterSpacing: '-0.72px',
    textTransform: 'uppercase',
    [theme.breakpoints.down(1685)]: {
      fontSize: ({ catalog }) => (catalog ? 28 : 32),
      lineHeight: '36px'
    },
    [theme.breakpoints.down(1585)]: {
      fontSize: ({ catalog }) => (catalog ? 24 : 28),
      lineHeight: '36px'
    },
    [theme.breakpoints.down(1350)]: {
      fontSize: ({ catalog }) => (catalog ? 18 : 22),
      lineHeight: '32px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: ({ catalog }) => (catalog ? 18 : 20),
      lineHeight: '28px',
      display: ({ catalog }) => (catalog ? 'none' : 'inherit')
    },

    [theme.breakpoints.down(1085)]: {
      fontSize: ({ catalog }) => (catalog ? 18 : 14)
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: ({ catalog }) => (catalog ? 16 : 20),
      display: ({ catalog }) => (catalog ? 'none' : 'none'),
      lineHeight: '24px'
    }
  },
  membershipTextPromoDesc: {
    color: '#131415',
    fontFamily: 'Inter',
    textAlign: 'center',
    fontSize: 28,
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '-0.72px',
    textTransform: 'uppercase',
    marginBottom: 0,
    '& span': {
      color: '#2663FF'
    },
    [theme.breakpoints.down(1585)]: {
      fontSize: 24,
      lineHeight: '28px'
    },
    [theme.breakpoints.down(1350)]: {
      fontSize: 18,
      lineHeight: '24px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      lineHeight: '24px'
    },
    [theme.breakpoints.down(1085)]: {
      fontSize: 17,
      lineHeight: '20px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      lineHeight: '20px'
    }
  },
  membershipTextPromoTime: {
    color: '#131415',
    fontFamily: 'Inter',
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: '-0.72px',
    textTransform: 'uppercase',
    marginBottom: 4,
    '& span': {
      color: '#2663FF'
    },
    [theme.breakpoints.down(1585)]: {
      fontSize: 20,
      lineHeight: '24px'
    },
    [theme.breakpoints.down(1350)]: {
      fontSize: 16,
      lineHeight: '18px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: '16px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      lineHeight: '16px'
    }
  },
  membershipBasicContainer: {
    padding: '0px 32px',
    [theme.breakpoints.down(1585)]: {
      padding: '0px 24px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px 16px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 12px'
    }
  },
  membershipPromocContainer: {
    height: 90
  },
  membershipCTA: {
    width: '100%',
    height: 32,
    maxWidth: 164,
    border: 'none',
    borderRadius: 16,
    background: '#131415',
    fontFamily: 'Inter',
    '& span': {
      color: '#ffffff'
    },
    '&:hover': {
      background: 'rgb(235, 237, 240) !important',
      '& span': {
        color: '#131415'
      }
    },
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    [theme.breakpoints.down(1585)]: {
      width: 152,
      fontSize: 15,
      height: 32,
      borderRadius: 16,
      paddingLeft: 6,
      paddingRight: 6
    },
    [theme.breakpoints.down(1350)]: {
      width: 108,
      fontSize: 14,
      height: 32,
      borderRadius: 16,
      paddingLeft: 4,
      paddingRight: 4
    },
    [theme.breakpoints.down('md')]: {
      width: 90,
      fontSize: 12,
      height: 24,
      borderRadius: 8,
      paddingLeft: 8,
      paddingRight: 8
    },
    [theme.breakpoints.down('sm')]: {
      width: 60,
      fontSize: 8,
      height: 18,
      borderRadius: 6,
      paddingLeft: 4,
      paddingRight: 4
    }
  },
  noMembershipCTA: {
    height: ' 50px',
    background: '#2663FF',
    border: 'none',
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px'
  },
  closeIconButton: {
    padding: 6,
    [theme.breakpoints.down('md')]: {
      marginTop: 12
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    }
  },
  closeIcon: {
    color: '#8d9299',
    height: 18,
    width: 18
  },
  bannerImageContainerSanta: {
    height: 40,
    position: 'relative',
    [theme.breakpoints.down(1585)]: {
      width: ({ catalog }) => (catalog ? '102px' : '132px'),
      height: ({ catalog }) => (catalog ? 24 : 36)
    },
    [theme.breakpoints.down('md')]: {
      height: ({ catalog }) => (catalog ? 36 : 37),
      width: ({ catalog }) => (catalog ? '72px' : '82px')
    },
    [theme.breakpoints.down(1085)]: {
      height: ({ catalog }) => (catalog ? 36 : 37),
      display: ({ catalog }) => (catalog ? 'none' : 'inherit')
    },
    [theme.breakpoints.down('xs')]: {
      height: 12,
      display: ({ catalog }) => (catalog ? 'none' : 'none')
    }
  },
  bannerImageContainer: {
    width: '164px',
    height: 72,
    position: 'relative',
    [theme.breakpoints.down(1685)]: {
      width: ({ catalog }) => (catalog ? '132px' : '164px')
    },
    [theme.breakpoints.down(1585)]: {
      width: ({ catalog }) => (catalog ? '92px' : '132px')
    },
    [theme.breakpoints.down('md')]: {
      width: ({ catalog }) => (catalog ? '82px' : '92px'),
      display: ({ catalog }) => (catalog ? 'none' : 'inherit')
    },
    [theme.breakpoints.down(1085)]: {
      width: ({ catalog }) => (catalog ? '62px' : '72px'),
      display: ({ catalog }) => (catalog ? 'none' : 'none')
    },
    [theme.breakpoints.down('xs')]: {
      width: ({ catalog }) => (catalog ? '64px' : '112px')
    }
  },
  bannerImage: {
    width: '100%',
    height: `100%`,
    objectFit: 'contain'
  },
  bannerImageSanta: {
    objectFit: 'contain',
    width: '100%',
    height: `100%`,
    position: 'absolute',
    height: 44,
    top: -2,
    left: 4,
    [theme.breakpoints.down(1585)]: {
      height: ({ catalog }) => (catalog ? 30 : 38)
    },
    [theme.breakpoints.down('md')]: {
      height: ({ catalog }) => (catalog ? 36 : 34),
      left: ({ catalog }) => (catalog ? -12 : 4)
    },
    [theme.breakpoints.down('xs')]: {
      height: 32
    }
  },
  bannerStar: {
    position: 'absolute'
  },
  middleStarts: {
    [theme.breakpoints.down(1350)]: {
      display: 'none'
    }
  }
});

export default styles;
