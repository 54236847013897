const customScrollBar = {
  overflowY: 'auto',
  overflowX: 'hidden',
  width: 'auto',
  marginRight: -1,
  '&::-webkit-scrollbar': {
    backgroundColor: '#efeff2',
    width: 1,
    borderRadius: 1
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#d3dbe5',
    width: 1,
    borderRadius: 1
  }
};

export default theme => ({
  root: {
    marginBottom: theme.spacing(1),
    position: 'relative',
    background: '#fff'
  },
  headerBar: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    background: '#fff',
    position: 'sticky',
    left: 0
    // right: 0
  },
  categorySectionsContainer: {
    ...customScrollBar,
    maxHeight: 'calc(100vh - 232px)'
  },
  filterContainer: {
    margin: '20px 0px',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  categoryContainer: {
    position: 'relative',
    marginBottom: 40,
    '& > div.category-scroll-padding': {
      // position: 'absolute',
      top: 0
    }
  },
  nextBtnContainer: {
    display: 'none',
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 75,

    [theme.breakpoints.down('xs')]: {
      display: 'flex'
    }
  },
  nextBtn: {
    margin: 'auto',
    maxWidth: 280
  },
  topCatalogHeader: {
    fontFamily: 'Inter',
    fontSize: '40px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '52px' /* 130% */,
    letterSpacing: '-1.2px',
    color: '#3577D4',
    // marginBottom: 18,
    position: 'relative',
    top: 20,
    height: 110,
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: 34
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 27,
      marginBottom: 10
    }
  },
  subHeader: {
    fontFamily: 'Inter',
    fontSize: 14,
    lineHeight: 1,
    height: 30,
    fontWeight: 400,
    color: '#4A4F54',
    [theme.breakpoints.down(1522)]: {
      fontSize: 12
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  collectionChip: {
    height: '40px',
    background: '#131415',
    color: '#FFFFFF',
    borderRadius: '10px',
    paddingRight: '12px',
    '&.MuiChip-deletable:focus': {
      backgroundColor: '#131415'
    },
    '& .MuiChip-label': {
      color: '#FFFFFF',
      fontSize: '12px',
      fontFamily: 'Inter',
      fontWeight: 400,
      lineHeight: '16px'
    },
    '& .MuiChip-deleteIcon': {
      color: '#FFFFFF',
      cursor: 'pointer'
    }
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    '& > *': {
      margin: theme.spacing(0.5)
    },
    // height: '100px',
    overflowX: 'hidden',
    marginBottom: '20px',
    position: 'relative',
    left: '7px'
  },
  row: {
    display: 'flex',
    width: '100%',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    height: 40,
    alignItems: 'center',
    gap: '10px',
    margin: '5px 0px'
  },
  scrollable: {
    // maxHeight: 100,
    overflowY: 'auto'
  }
});
