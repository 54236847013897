import React from 'react';
import { Badge, Grid, makeStyles, useMediaQuery } from '@material-ui/core';
import { Typography } from '@swagup-com/components';

const styles = theme => ({
  newBadgeContainer: ({ size, fontColor }) => ({
    height: 'auto',
    width: 'auto',
    background: '#EBF1FB',
    position: 'absolute',
    borderRadius: size === 'small' ? '8px' : '16px',
    padding: size === 'small' ? '4px 8px' : '6px 12px',
    // right: size === 'small' ? -14 : -18,
    // right: -20,
    color: fontColor || 'inherit',
    fontSize: size === 'small' ? '11px' : '12px',
    '& p': {
      color: '#2663FF',
      fontWeight: '600',
      fontSize: size === 'small' ? '6px' : '12px',
      lineHeight: size === 'small' ? '10px' : '16px'
    },
    [theme.breakpoints.down(1685)]: {
      '& p': {
        fontWeight: '500',
        fontSize: 6,
        lineHeight: '12px'
      },
      borderRadius: '4px',
      padding: '2px 6px'
    }
  })
});

const useStyles = makeStyles(styles);

const BlueBadge = ({ size, children, badgeContent, mobileBadgeContent, position = 'right' }) => {
  const classes = useStyles({ size });
  const isXs = useMediaQuery(theme => theme.breakpoints.down('xs'));

  return (
    // <Badge
    //   overlap="rectangular"
    //   badgeContent={badgeContent}
    //   style={{ right: position === 'right' ? 0 : undefined }}
    //   color="primary"
    //   // classes={{ badge: classes.newBadgeContainer }}
    // >
    //   <Typography variant="body4MediumInter">{children}</Typography>
    // </Badge>
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.newBadgeContainer}
      style={{ right: position === 'right' ? 0 : undefined }}
    >
      <Grid item>
        <Typography variant="body4MediumInter">
          {isXs && mobileBadgeContent ? mobileBadgeContent : badgeContent}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BlueBadge;
