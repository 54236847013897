import React from 'react';
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  IconButton,
  makeStyles,
  Grid
} from '@material-ui/core';
import { useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { addProductToCart, removeProductFromCart, addPresetPackToCart } from '../../../actions/cart';
import { resize } from '../../../utils/images';
import { buildUrlWithParam, getIconButton, isImageType, sortByType } from '../../../helpers/utils';
import styles from './Product.styles';
import DiscountBagde from '../../global/DiscountBadge';
import StrikeOutText from '../../global/StrikeOutCost';
import { useMembership } from '../../../contexts/membershipContext';
import { useAuth } from '../../global/Authentication/AuthProvider';
import { CustomTooltip } from '../../products/commonProductsElements';
import BlueBadge from '../../shared/BlueBadge';

const useStyles = makeStyles(styles);

const Product = ({ product }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const cart = useSelector(state => state.cart);
  const inCart = cart.items.some(item => item.product.id === product.id);

  const hazardousMaterialsAlready =
    cart.items.some(item => item.product.hazardous_materials) &&
    product.hazardous_materials &&
    location.pathname?.includes('/custom-swag-packs/catalog');

  const hazmatProduct = cart.items.find(item => item.product.hazardous_materials);

  const { swagupMembershipPlans, lifestyleImagesInCatalog, hoverEffectLifestyleImages } = useFlags();
  const {
    currentMembership: { isFreeTier, product_discount: productDiscount }
  } = useMembership();

  const { isAuthenticated } = useAuth();

  const handleToggle = () => {
    dispatch((inCart ? removeProductFromCart : addProductToCart)(product) || addPresetPackToCart(product));
  };

  const [imgSrc, imgAlt] = getIconButton(inCart);

  const hoverImage = product.catalog_files?.sort(sortByType).find(cf => isImageType(cf.file_type))?.url;
  const urlToOpen = buildUrlWithParam(location, 'details', product.id);
  const canHover = lifestyleImagesInCatalog && hoverEffectLifestyleImages && hoverImage;

  return (
    <Card className={classes.root}>
      <CardActionArea component={Link} replace to={urlToOpen} className={classes.actionArea}>
        <div style={{ padding: '8px 10px', position: 'absolute', width: '100%' }}>
          <div style={{ position: 'relative' }}>
            {!product.can_ship_international && (
              <BlueBadge badgeContent="US Shipping only" mobileBadgeContent="US only" />
            )}
          </div>
        </div>
        <CardMedia
          id="view-item"
          image={resize(product.img, 233, 257)}
          title={product.name}
          className={classes.image}
          style={{ opacity: canHover ? undefined : 1 }}
        />
        {canHover && (
          <CardMedia
            id="lifestyle-view-item"
            image={`${process.env.REACT_APP_STATIC_RESOURCES_HOST}/${hoverImage}`}
            title={product.name}
            className={classes.imageLifestye}
          />
        )}
      </CardActionArea>
      <CardContent className={classes.content}>
        <div className={classes.details}>
          <Typography className={classes.title}>{product.name}</Typography>
          <Grid container alignItems="center" spacing={1}>
            {!isFreeTier && swagupMembershipPlans && isAuthenticated && (
              <Grid item>
                <DiscountBagde discount={productDiscount} size="small" />
              </Grid>
            )}
            <Grid item xs>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs>
                  <Typography className={classes.price} style={{ margin: 0 }}>
                    Starting at{' '}
                    {!isFreeTier && productDiscount > 0 && swagupMembershipPlans && (
                      <StrikeOutText value={product.price_without_discount} fontSize={10} />
                    )}{' '}
                    <strong>{product.price}</strong>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <CustomTooltip
                title={`You cannot create a pack with more than 1 hazmat product. ${hazmatProduct?.product?.name} already in pack`}
                disableHoverListener={!hazardousMaterialsAlready}
              >
                <IconButton
                  color="primary"
                  aria-label="toggle-product"
                  disabled={hazardousMaterialsAlready}
                  style={{ background: hazardousMaterialsAlready ? '#d4d9e2' : 'transparent' }}
                  className={classes.button}
                  onClick={handleToggle}
                >
                  <img id="add-to-cart" src={imgSrc} alt={imgAlt} />
                </IconButton>
              </CustomTooltip>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};

export default Product;
