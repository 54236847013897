import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { Box, FormControlLabel, Grid, makeStyles, Slider } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Chip, Typography } from '@swagup-com/components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { isNumber, debounce, round, get } from 'lodash';
import PropTypes from 'prop-types';
// import Scrollable from 'react-scrollbars-custom';
import CategoriesSidebar from './CategoriesSidebar';
import Category from './Category';
import Loader from '../../global/Loader';
import styles from './CatalogNew.styles';
import { FilterBy, SearchField } from '../../products/commonProductsElements';
import { useQueryParams } from '../../../hooks';
import { moneyStr } from '../../../helpers/utils';
import useCatalogFilter from './useCatalogFilter';
// import { DEFAULT_COLLECTION_TO_BE_SELECTED } from '../../../utils/constants';
import { CATALOG_SET_SELECTED_COLLECTIONS } from '../../../actions/types';
import { chunkArray } from './Common';
import { toSentenceCase } from '../../account/MembershipCommon';
import { CatalogSearchField } from './commonElements';
import { fetchActiveColections } from '../../../actions/catalog';
import { BlueSwitch } from '../../account/AccountSharedComponents';

const useStyles = makeStyles(styles);

const useFragment = () => {
  const location = useLocation();
  return location.hash.slice(1);
};

const getDefaultPriceRange = catalog => {
  if (isEmpty(catalog)) return [0, 100];
  const allPrices = catalog.reduce((acc, cat) => [...acc, ...cat.items.map(item => Number(item.price))], []);
  return [Math.min(...allPrices.map(p => Math.floor(p))), Math.max(...allPrices.map(p => Math.ceil(p)))];
};

const valuetext = value => {
  return moneyStr(value);
};

const ValueLabelComponent = props => {
  const { children, index, value } = props;
  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          left: index ? '100%' : '0%',
          position: 'absolute'
        }}
      >
        <div style={{ position: 'relative' }}>
          <div
            style={{
              marginLeft: '-50%',
              marginTop: -30,
              // backgroundColor: '#8D9299',
              width: 'fit-content',
              padding: '2px 4px',
              fontFamily: 'Inter',
              fontSize: 10,
              borderRadius: 4,
              boxShadow: 'none',
              color: '#4A4F54'
            }}
          >
            {moneyStr(value)}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired
};

const CatalogNew = ({ nextStep, isBulk }) => {
  const classes = useStyles();
  const categoryName = useFragment();

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const { items: catalog, loading } = useSelector(state => state.catalog);
  const cartLoading = useSelector(state => state.cart.loading);

  const [selectedCategoryId, setSelectedCategoryId] = useState();

  const history = useHistory();
  const query = useQueryParams();
  const search = query.get('search') || '';
  const getCollections = query.get('collections')?.split(',') || [];
  const selectedCategories = query.get('categories');
  const defaultPriceRange = getDefaultPriceRange(catalog);
  const selectedPriceRange = query.get('price-range') || defaultPriceRange.join();
  const internationalShipping = query.get('us_only') === 'false';
  const [activeCollections, setActiveCollections] = useState([]);
  const initialCategoryValues = selectedCategories?.split(',').filter(icat => isNumber(Number(icat)));
  const initialPriceRageValues = selectedPriceRange?.split(',').map(range => Number(range));

  const { selectedCollections } = useSelector(state => state.catalog);

  const dispatch = useDispatch();

  const handleUpdateSelectedCollections = updatedCollections => {
    dispatch({
      type: CATALOG_SET_SELECTED_COLLECTIONS,
      payload: { selectedCollections: updatedCollections }
    });
  };

  const { filterCatalog, filterCatalogItems } = useCatalogFilter(
    initialCategoryValues,
    search,
    selectedPriceRange,
    defaultPriceRange,
    initialPriceRageValues,
    selectedCollections,
    internationalShipping
  );

  function collectionCleaner(array) {
    const cleanedArray = [];
    array.forEach(item => {
      if (item && item.includes(';')) {
        const splitItems = item
          ?.split(';')
          .map(item => item.trim())
          .filter(item => item !== '');
        cleanedArray.push(...splitItems);
      } else if (item !== null) {
        cleanedArray.push(item);
      }
      cleanedArray.unshift();
    });
    return Array.from(new Set(cleanedArray));
  }

  function extractDistinctCollections(items, distinctCollections) {
    items?.forEach(item => {
      const collection = item.collections;
      if (!distinctCollections.includes(collection && item.visible_in_collections)) {
        const i = activeCollections.findIndex(e => e.label === collection);
        if (i > -1) {
          if (activeCollections[i].is_active) {
            distinctCollections.push(collection);
          }
        }
      }
    });
    return distinctCollections;
  }

  const getDistinctCollection = catalog => {
    let distinctCollections = [];
    catalog?.forEach(item => {
      distinctCollections = extractDistinctCollections(item.items, distinctCollections);
    });
    distinctCollections = collectionCleaner(distinctCollections);
    return distinctCollections;
  };

  const collections = getDistinctCollection(catalog);

  const catalogCategories = useMemo(
    () =>
      catalog?.filter(filterCatalog).map(cat => {
        const category = {
          ...cat,
          items: cat.items.filter(filterCatalogItems)
        };
        return category;
      }) || [],
    [catalog, filterCatalog, filterCatalogItems]
  );

  const sortByOptions = ['All'];

  const refs = useMemo(() => catalogCategories?.map(() => React.createRef()), [catalogCategories]);
  const categoryContainerElementRef = useRef(null);

  const handleSelectedCategory = useCallback(
    categoryIdx => {
      if (!catalogCategories?.[categoryIdx]) return;
      const element = refs[categoryIdx].current;
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
    [catalogCategories, refs]
  );

  const categories = useMemo(
    () => (
      <Grid container direction="column" alignItems="stretch">
        {catalogCategories.map((category, idx) => {
          return (
            <Grid item key={category.id} className={classes.categoryContainer}>
              <div id={category.CatName} className="category-scroll-padding" ref={refs[idx]} />
              <Category category={category} />
            </Grid>
          );
        })}
      </Grid>
    ),
    [catalogCategories, classes.categoryContainer, refs]
  );

  // useEffect(() => {
  //   if (!isEmpty(catalogCategories)) setSelectedCategoryId(catalogCategories[0].id);
  // }, [catalogCategories]);

  useEffect(() => {
    dispatch(fetchActiveColections).then(response => {
      setActiveCollections(response);
    });
  }, []);

  const handelShowCollection = items => {
    if (items[0] == '') items.shift();
    handleUpdateSelectedCollections(items);
    query.set('collections', items);
    history.replace({ ...history.location, search: query.toString() });
  };

  useEffect(() => {
    if (getCollections[0] == '') getCollections.shift();
    if (getCollections.length > 0) handleUpdateSelectedCollections(getCollections);
    else {
      handleUpdateSelectedCollections([]);
    }
  }, []);

  useEffect(() => {
    if (isEmpty(catalogCategories)) return () => {};

    const handleScroll = () => {
      const idx = refs.findIndex(
        ({ current }) => current?.parentNode.offsetTop >= categoryContainerElementRef.current.scrollTop + 300
      );
      setSelectedCategoryId(catalogCategories[(idx >= 0 ? idx : catalogCategories.length) - 1].id);
    };
    if (categoryContainerElementRef.current) {
      categoryContainerElementRef.current.addEventListener('scroll', handleScroll, false);
    }

    return () => {
      if (categoryContainerElementRef.current) {
        categoryContainerElementRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [catalogCategories, refs]);

  useEffect(() => {
    handleSelectedCategory(catalogCategories?.findIndex(c => c.CatName === categoryName));
  }, [catalogCategories, categoryName, handleSelectedCategory]);

  const searchByQueryParam = useCallback(
    value => {
      query.set('search', value);
      history.replace(`${history.location?.pathname}?${query.toString()}`);
    },
    [query, history]
  );

  const filterByShippingParam = useCallback(
    value => {
      if (!value) query.set('us_only', value);
      else query.delete('us_only');
      history.replace(`${history.location?.pathname}?${query.toString()}`);
    },
    [query, history]
  );

  const handleOnApply = React.useCallback(
    queryName => value => {
      if (!value) {
        query.delete(queryName);
      } else {
        query.set(queryName, value);
      }
      history.replace({ ...history.location, search: query.toString() });
    },
    [history, query]
  );

  const updatePriceRange = React.useCallback(
    (event, newValue) => {
      // scale={x => x * (x / defaultPriceRange[1])}
      const minVal = round(newValue[0] * (newValue[0] / defaultPriceRange[1]), 2);
      const maxVal = round(newValue[1] * (newValue[1] / defaultPriceRange[1]), 2);
      if (minVal === defaultPriceRange[0] && maxVal === defaultPriceRange[1]) {
        query.delete('price-range');
      } else {
        query.set('price-range', [minVal, maxVal].join());
      }
      history.replace({ ...history.location, search: query.toString() });
    },
    [history, defaultPriceRange, query]
  );

  const handlePriceRangeChange = debounce(updatePriceRange, 750);

  const categoryOptions = catalog?.reduce((acc, cat) => ({ ...acc, [cat.id]: cat.CatName }), {});

  if (isEmpty(catalog) || loading) return <Loader />;

  const header = `Results for Your Selection`;

  const handleSelectedCollectionItemDelete = deletedCollectionItem => {
    const updatedCollections = selectedCollections.filter(item => item !== deletedCollectionItem);
    handelShowCollection(updatedCollections);
  };

  const selectedCollectionItemRows = chunkArray(selectedCollections, 6);

  return (
    <div className={classes.root}>
      <Grid>
        <Grid item>
          <Box className={classes.filterContainer}>
            <Grid container spacing={4} alignItems="center">
              <Grid item>
                <FilterBy
                  key={`categories=${selectedCategories}`}
                  label="Category:"
                  options={categoryOptions}
                  initialValues={initialCategoryValues}
                  onApply={handleOnApply('categories')}
                />
              </Grid>
              <Grid item>
                <Grid container justifyContent="flex-end" alignItems="center" spacing={4}>
                  <Grid item>
                    <Typography variant="body2RegularInter">Price range:</Typography>
                  </Grid>
                  <Grid item style={{ minWidth: 150, paddingRight: 10 }}>
                    <Slider
                      // value={initialPriceRageValues}
                      defaultValue={initialPriceRageValues}
                      ValueLabelComponent={ValueLabelComponent}
                      onChange={handlePriceRangeChange}
                      valueLabelDisplay="on"
                      aria-labelledby="range-slider"
                      getAriaValueText={valuetext}
                      min={defaultPriceRange[0]}
                      max={defaultPriceRange[1]}
                      scale={x => {
                        const minPrice = defaultPriceRange[0];
                        const calculatedScale = x * (x / defaultPriceRange[1]);
                        return calculatedScale < minPrice ? minPrice : calculatedScale;
                      }}
                      style={{ margin: '8px 0px 0px 6px' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <BlueSwitch
                      // disabled={isSaving}
                      checked={internationalShipping}
                      onChange={({ target: { checked } }) => filterByShippingParam(!checked)}
                    />
                  }
                  label={
                    <p style={{ fontSize: 16, fontFamily: 'Inter', margin: '0px 4px 0px 16px' }}>
                      International shipping
                    </p>
                  }
                />
              </Grid>
              <Grid item xs />
              <Grid item xs={3}>
                <CatalogSearchField
                  key="search"
                  placeholder="Search Product"
                  onChange={searchByQueryParam}
                  defaultValue={search}
                  rounded
                  // lean
                  // inverseHover
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item style={{ marginBottom: '20px' }}>
          <Typography variant="h2SemiBoldInter">{toSentenceCase(header)}</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.chipContainer}
        style={{
          height: selectedCollections?.length < 1 ? 0 : undefined,
          marginBottom: selectedCollections?.length < 1 ? 0 : undefined
        }}
      >
        <Grid
          container
          // style={{ height: selectedCollectionItemRows.length > 1 ? 100 : 'auto' }}
          className={classes.scrollable}
        >
          {selectedCollectionItemRows.map((selectedCollectionItemRows, rowIndex) => (
            <Grid key={rowIndex} className={classes.row}>
              {selectedCollectionItemRows.map((selectedCollectionItem, wordIndex) => (
                <Chip
                  key={wordIndex}
                  label={selectedCollectionItem}
                  onDelete={() => handleSelectedCollectionItemDelete(selectedCollectionItem)}
                  className={classes.collectionChip}
                />
              ))}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <CategoriesSidebar
        catalog={catalogCategories}
        collections={collections}
        handelShowCollection={handelShowCollection}
        activeCategoryId={selectedCategoryId}
        onSelectedCategory={handleSelectedCategory}
      />
      <Box ref={categoryContainerElementRef} className={classes.categorySectionsContainer}>
        {categories}
      </Box>
      <div className={classes.nextBtnContainer}>
        {nextStep && (
          <Button
            variant="primary"
            loading={cartLoading}
            disabled={cartLoading}
            component={Link}
            to={nextStep}
            className={classes.nextBtn}
            fullWidth
          >
            Next: Add project details
          </Button>
        )}
      </div>
    </div>
  );
};

export default CatalogNew;
