import React from 'react';
import clsx from 'clsx';
import { Grid, withStyles } from '@material-ui/core';
import styles from './styles/PaymentSection';

const WireTransfer = ({ classes }) => (
  <Grid container>
    <Grid item xs={12}>
      <p className={clsx(classes.formParagraph, classes.pPaddingBottom)}>
        To send payment you’ll need the following information:
      </p>
      <h3 className={classes.tabTitle}>Bank information</h3>
      <p className={classes.formParagraph}>PNC Bank</p>
      <p className={classes.formParagraph}>500 First Ave.</p>
      <p className={clsx(classes.formParagraph, classes.pPaddingBottom)}>Pittsburgh, PA 15219</p>
      <h3 className={classes.tabTitle}>Routing number</h3>
      <p className={clsx(classes.formParagraph, classes.pPaddingBottom)}>043000096</p>
      <h3 className={classes.tabTitle}>Account number</h3>
      <p className={clsx(classes.formParagraph, classes.pPaddingBottom)}>1087104121</p>
      <h3 className={classes.tabTitle}>Swift code</h3>
      <p className={clsx(classes.formParagraph, classes.pPaddingBottom)}>PNCCUS33</p>
      <hr />
      <p className={clsx(classes.formParagraph, classes.pPaddingTop)} style={{ textAlign: 'center' }}>
        Once payment is received we will start production of your order.
      </p>
    </Grid>
  </Grid>
);

export default withStyles(styles)(WireTransfer);
