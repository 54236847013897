import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useCatalogFilter = (
  initialCategoryValues,
  search,
  selectedPriceRange,
  defaultPriceRange,
  initialPriceRageValues,
  showCollectionsItems,
  internationalShipping
) => {
  const { catalogCollections } = useFlags();

  const filterCatalog = useCallback(
    category => {
      if (
        (isEmpty(initialCategoryValues) || initialCategoryValues.find(c => c === `${category.id}`)) &&
        getCategoryFilter(category, showCollectionsItems).find(
          item =>
            (isEmpty(search) ||
              item.name.toLowerCase().includes(search.toLowerCase()) ||
              item.description.toLowerCase().includes(search.toLowerCase())) &&
            (selectedPriceRange === defaultPriceRange.join() ||
              (Number(item.price) >= initialPriceRageValues[0] && Number(item.price) <= initialPriceRageValues[1])) &&
            (!internationalShipping || item.can_ship_international)
        )
      )
        return true;

      return false;
    },
    [initialCategoryValues, search, selectedPriceRange, defaultPriceRange, initialPriceRageValues]
  );

  const filterCatalogItems = useCallback(
    item => {
      if (filterStrings(item, showCollectionsItems)) {
        if (
          (isEmpty(search) ||
            item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.description.toLowerCase().includes(search.toLowerCase())) &&
          (selectedPriceRange === defaultPriceRange.join() ||
            (Number(item.price) >= initialPriceRageValues[0] && Number(item.price) <= initialPriceRageValues[1])) &&
          (!internationalShipping || item.can_ship_international) &&
          (showCollectionsItems?.length < 1 || item.visible_in_collections)
        )
          return true;
      }
      return false;
    },
    [search, selectedPriceRange, defaultPriceRange, initialPriceRageValues]
  );

  const filterStrings = (item, elementsArray) => {
    if (!catalogCollections) return true;
    if (elementsArray?.length === 0) return true;
    if (!item.visible_in_collections) return false;
    const targetStrings = item.collections;
    const targetArray = targetStrings?.split(';') || [];
    for (const element of targetArray) {
      if (elementsArray?.includes(element)) return true;
    }
    return false;
  };

  const getCategoryFilter = (category, showCollectionsItems) => {
    if (!catalogCollections) return category.items;
    return category.items.filter(item => filterStrings(item, showCollectionsItems));
  };
  return { filterCatalog, filterCatalogItems };
};

export default useCatalogFilter;
